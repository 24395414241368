import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sortBy } from "lodash";
import {
  selectFilteredLeaguesList,
  selectLeaguesFetching,
} from "../../redux/leagues/leaguesSelectors";
import LeaguesListItem from "../LeaguesListItem";
import "./leaguesList.scss";
import { selectFixturesLeagues } from "../../redux/fixtures/fixturesSelectors";
import {
  selectLiveFixturesLeagues,
  selectLiveMatchesDate,
} from "../../redux/live/liveSelectors";
import { searchLeagues } from "../../utils";

const LeaguesList = ({
  leagues,
  type,
  selectedLeaguesList,
  matchLeagues,
  setSelectedLeaguesList,
  selectedLeaguesListIds,
  setSelectedLeaguesListIds,
  fixturesLeagues,
  isFetchingLeagues,
  route,
  liveFixturesLeagues,
  liveDate,
  inputVal,
}) => {
  const leaguesList =
    type === "filterModal"
      ? route === "live"
        ? liveFixturesLeagues &&
          searchLeagues(liveFixturesLeagues[liveDate], inputVal)
        : fixturesLeagues &&
          searchLeagues(Object.values(fixturesLeagues), inputVal)
      : leagues
      ? leagues
      : null;
  console.log(leaguesList.sort((a, b) => a.priority - b.priority));
  console.log("leaguesList", leaguesList);
  return (
    <div
      className={`leagues-list-container ${
        type === "filterModal" && "filterModal-leagues-list-container"
      }
      `}>
      <div className={`leagues-list`}>
        {isFetchingLeagues
          ? "Loading..."
          : (leaguesList && leaguesList.length === 0) ||
            leaguesList === undefined
          ? "no country or competition was found"
          : leaguesList
              .sort((a, b) => a.priority - b.priority)
              .map((league) => (
                <LeaguesListItem
                  key={league?.league_id}
                  league={league}
                  type={type}
                  matchLeagues={matchLeagues}
                  selectedLeaguesList={selectedLeaguesList}
                  setSelectedLeaguesList={setSelectedLeaguesList}
                  selectedLeaguesListIds={selectedLeaguesListIds}
                  setSelectedLeaguesListIds={setSelectedLeaguesListIds}
                />
              ))}
      </div>
      <div></div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  leagues: selectFilteredLeaguesList(ownProps.inputVal)(state),
  fixturesLeagues: selectFixturesLeagues(state),
  liveFixturesLeagues: selectLiveFixturesLeagues(state),
  isFetchingLeagues: selectLeaguesFetching(state),
  liveDate: selectLiveMatchesDate(state),
});

LeaguesList.propTypes = {
  leagues: PropTypes.array,
  fixturesLeagues: PropTypes.object,
  type: PropTypes.string,
  selectedLeaguesList: PropTypes.array,
  matchLeagues: PropTypes.array,
  setSelectedLeaguesList: PropTypes.func,
  selectedLeaguesListIds: PropTypes.array,
  setSelectedLeaguesListIds: PropTypes.func,
  liveFixturesLeagues: PropTypes.object,
  isFetchingLeagues: PropTypes.bool,
  route: PropTypes.string,
  liveDate: PropTypes.string,
  inputVal: PropTypes.string,
};

export default connect(mapStateToProps)(LeaguesList);
