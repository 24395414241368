import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import PropTypes from "prop-types";
import { FaDirections } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import StarRatings from "react-star-ratings";
import { connect } from "react-redux";
import "./attraction-modal.scss";
import { FaAngleRight, FaAngleLeft, FaMapMarkerAlt } from "react-icons/fa";
// import { FaAngleLeft } from "react-icons/fa";
import { ReactComponent as PubIcon } from "../../assets/images/pub.svg";
import { ReactComponent as RestaurantIcon } from "../../assets/images/restaurant.svg";
import thoughts from "../../assets/images/thoughts.png";
import footbalOne from "../../assets/images/footbal-1.avif";
import footbalTwo from "../../assets/images/footbal-2.avif";
import footbalThree from "../../assets/images/footbal-3.jpg";
import { createStructuredSelector } from "reselect";
import { selectFixture } from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import { selectRecomendation } from "../../redux/attractions/attractionSelectors";
import { RiCloseLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { fetchGoogleImages } from "../../helpers/getGoogleImages";
import Mixpanel from "../../utils/mixPanel";

const AttractionModal = ({
  setAttractionModal,
  attractionModal,
  attraction,
  fixture,
  attractionRecomendation,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [imagesAttraction, setImagesAttraction] = useState([]);

  const history = useHistory();
  const { lat, lng } = fixture;
  const goToPreviousSlide = () => {
    Mixpanel.track(`Click on attraction iamges on attraction Details`)
    const newIndex = Math.max(startIndex - 2, 0);
    setStartIndex(newIndex);
  };
  Mixpanel.track(`Click on Attraction Details`);
  const goToNextSlide = () => {
    Mixpanel.track(`Click on attraction iamges on attraction Details`)
    const newIndex = Math.min(startIndex + 2, imagesAttraction.length - 2);
    setStartIndex(newIndex);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetchGoogleImages(
          `${attraction.title} ${attraction.attraction_type.type_name} ${attraction.address}`
        );
        setImagesAttraction(response);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [attraction.title]);
  const { attractionsRecomendationData, averageRating } =
    attractionRecomendation;
  return (
    <Modal
      styles={{ modal: { padding: 0, borderRadius: 10 } }}
      showCloseIcon={false}
      open={attractionModal}
      onClose={() => setAttractionModal(false)}
      center>
      <div className="filter-modal">
        <div className="filter-modal-content">
          <RiCloseLine
            onClick={() => setAttractionModal(false)}
            className="closeIcon"
            size={25}
            color="#2e2e2e"
          />
          {/* <div className="title-container">
            <p>{"title"} </p>
            {attraction.attraction_type.type_name === "pubs" ? (
              <PubIcon className="pub-icon" />
            ) : (
              <RestaurantIcon className="pub-icon" />
            )}
          </div>
          <div className="desc-container">
            <p>{"address"}</p>
            <a
              href={`https://www.google.com/maps/dir/'${lat},${lng}'/'${attraction?.location.coordinates[1]},${attraction?.location.coordinates[0]}'`}
              target="_blank"
              rel="noopener noreferrer"
            >
            <button className="directions-btn">
              <FaDirections size={15} color="white" />
            </button>
            </a>
          </div> */}

          {/* <p style={{ fontSize: "12px" }}>{"attraction.details"}</p> */}

          {/* <div className="carousel">
            <FaAngleLeft onClick={goToPreviousSlide} />
            <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} />
            <FaAngleRight onClick={goToNextSlide} />
          </div>
          {attractionRecomendation && attractionRecomendation.length > 0 && (
            <div className="star-carousel">
              <FaAngleLeft onClick={prevReview} />
              <div className="star-container">
                <StarRatings
                  starDimension="22px"
                  starSpacing="1px"
                  rating={currentReview.ratings}
                  starEmptyColor="#2e2e2e"
                  starRatedColor="#FFDA1A"
                />
                <p className="star-text">{currentReview.share_your_thoughts}</p>
              </div>
              <FaAngleRight onClick={nextReview} />
            </div>
          )} */}

          <div className="attraction-modal-content">
            <div className="product-container">
              <div className="product-image">
                <img
                  src={imagesAttraction[0]?.url}
                  onError={(e) => (e.target.src = imagesAttraction[0]?.url)}
                />
              </div>
              <div>
                <h2>{attraction.title}</h2>

                <div className="product-rating-review">
                  <StarRatings
                    starDimension="19px"
                    starSpacing="1px"
                    rating={averageRating ? averageRating : 0}
                    starEmptyColor="#7d8185"
                    starRatedColor="#FFDA1A"
                  />
                  (
                  {attractionsRecomendationData
                    ? attractionsRecomendationData.length
                    : 0}
                  )
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <FaMapMarkerAlt
                style={{ marginRight: "8px", color: "#7d8185" }}
              />

              <div className="product-detail">
                <p>{attraction.address}</p>
                <a
                  href={`https://www.google.com/maps/dir/'${lat},${lng}'/'${attraction?.location.coordinates[1]},${attraction?.location.coordinates[0]}'`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <button className="directions-btn">
                    <FaDirections size={15} color="white" />
                  </button>
                </a>
              </div>
            </div>
            {attraction?.details && (
              <div className="product-desc">
                <h4>Description</h4>
                {/* <p>djnc jnds jnkd dnj nk sd nkn d jk </p> */}
                <div>
                  <div className="description">
                    {showFullText
                      ? attraction?.details
                      : String(attraction?.details).length > 100
                      ? `${attraction?.details?.slice(0, 100)}...`
                      : attraction?.details}
                  </div>
                  {String(attraction?.details).length > 100 && (
                    <button
                      className="see-more-btn"
                      onClick={() => setShowFullText(!showFullText)}>
                      {showFullText ? "See less" : "See more"}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="image-container">
            <h4 className="image-title">Photos & Videos</h4>
            <div className="carousel">
              <FaAngleLeft onClick={goToPreviousSlide} />
              <div className="carousel-images">
                {imagesAttraction
                  .slice(startIndex, startIndex + 2)
                  .map((image, index) => (
                    <img
                      key={index}
                      src={image.url}
                      onError={(e) => (e.target.src = image.url)}
                      alt={`Slide ${startIndex + index}`}
                    />
                  ))}
              </div>
              <FaAngleRight onClick={goToNextSlide} />
            </div>
          </div>

          <div className="review-container">
            <h4 className="review-title">Reviews</h4>
            <div
              className={` ${
                isOpen ? "review-content-setup-open" : "review-content-setup"
              } `}>
              {attractionsRecomendationData &&
                attractionsRecomendationData.length > 0 &&
                attractionsRecomendationData
                  .slice(0, isOpen ? undefined : 2)
                  .map((item, index) => (
                    <div className="review-content" key={index}>
                      <p>{item.share_your_thoughts}</p>
                      <div className="rating-section">
                        <StarRatings
                          starDimension="15px"
                          starSpacing="1px"
                          starEmptyColor="#7d8185"
                          starRatedColor="#FFDA1A"
                          numberOfStars={1}
                          rating={item.ratings / 5}
                        />
                        {item.ratings}
                      </div>
                    </div>
                  ))}
            </div>
            {attractionsRecomendationData &&
              attractionsRecomendationData.length > 0 && (
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="see-al-btn">
                  {isOpen ? "see less" : "see all"}
                </button>
              )}
          </div>

          <button
            className="review-pub-btn"
            onClick={() =>{
              Mixpanel.track(`Click on review on attraction Details`)
              history.push(
                `/review?title=${attraction.title}&lng=${attraction?.location?.coordinates[0]}&lat=${attraction?.location?.coordinates[1]}&address=${attraction.address}&type=${attraction.attraction_type.type_name}`
              )}
            }>
            <IoIosAddCircleOutline className="add-icon" />
            ADD REVIEW
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // fetchFilteringValues: (payload) => dispatch(fetchFilteringValues(payload)),
  // fetchIsAllLeaguesChecked: (payload) =>
  //   dispatch(fetchIsAllLeaguesChecked(payload)),
});

const mapStateToProps = createStructuredSelector({
  fixture: selectFixture,
  attractionRecomendation: selectRecomendation,
});

AttractionModal.propTypes = {
  setAttractionModal: PropTypes.func,
  attractionModal: PropTypes.bool,
  // attraction: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttractionModal);
