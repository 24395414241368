import React, { useState } from "react";
import "./resetpassword.scss";
import signInImg from "../../assets/images/signin-img.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  resetPasswordCall,
  verifyOtpApiCall,
} from "../../redux/auth/authServices";
import { showError } from "../../components/ToastMessage";
import { AuthRight } from "../../components/AuthRight/authright";

const UI_TEXT = {
  code: {
    title: "Verify Otp",
    btnText: "Verify",
    subTitle: "Please enter the code sent to your email",
  },
  reset: {
    title: "Create a new password",
    btnText: "Continue",
    subTitle: "Please create your new password.",
  },
};
const RigthSideScreen = {
  code: {
    title: "Forget password",
    btnText: "Verify",
    subTitle: "",
  },
  reset: {
    title: "Create a new password",
    btnText: "Continue",
    subTitle: "Please create your new password.",
  },
};

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [code, setCode] = useState("");
  const [cpassword, setCpassword] = useState("");
  const history = useHistory();

  // Handler for individual input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    const newValue = value.slice(0, 1);

    setCode((prevCode) => {
      const newCode =
        prevCode.slice(0, id) + newValue + prevCode.slice(parseInt(id) + 1);
      return newCode;
    });

    if (newValue) {
      const nextInput = document.getElementById(`${parseInt(id) + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "confirm") {
      setCpassword(value);
    }
  };

  const handleClick = async () => {
    if (password.trim() === "") {
      showError("Password cannot be empty");
      return;
    }

    if (cpassword === "") {
      const errorMessage = "Confirm Password cannot be empty";
      showError(errorMessage);
      return;
    }
    if (
      String(password).toLocaleLowerCase() !=
      String(cpassword).toLocaleLowerCase()
    ) {
      const errorMessage = "Password & Confirm Password must match.";
      showError(errorMessage);
      return;
    }

    // Call API
    const data = await resetPasswordCall({ password }, apiResponse.data);
    if (data?.success) {
      history.push("/sign-in");
    }
  };

  const handleCodeClick = async () => {
    // Basic validation
    if (code.length !== 6) {
      showError("Code must be 6 digits");
      return;
    }

    // Call API
    const data = await verifyOtpApiCall({ code });
    if (data?.success) {
      setApiResponse(data);
    }
  };
  const isReset = apiResponse?.success;
  const text = isReset ? UI_TEXT.reset : UI_TEXT.code;
  const rightSidetext = isReset ? RigthSideScreen.reset : RigthSideScreen.code;

  return (
    <div className="signin-container">
      <div className="sigin-left-section">
        <div className="sigin-left">
          <h2 className="heading">{text.title}</h2>
          <p>{text.subTitle}</p>
          <div className="input-container">
            {!isReset && (
              <div className="code-input-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    id={index}
                    value={code[index] || ""}
                    onChange={handleChange}
                    maxLength="1"
                    placeholder="-"
                    className="code-input"
                  />
                ))}
              </div>
            )}
            {isReset && (
              <>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                  placeholder="Enter Password"
                  className=""
                />
                <input
                  type="password"
                  name="confirm"
                  value={cpassword}
                  onChange={handleInputChange}
                  placeholder="Confirm Password"
                  className=""
                />
              </>
            )}
          </div>
          <button
            className="login-btn"
            onClick={() => {
              if (!isReset) {
                handleCodeClick();
              } else {
                handleClick();
              }
            }}>
            {text.btnText}
          </button>
        </div>
      </div>
      <AuthRight title={rightSidetext.title} subTitle={rightSidetext.subTitle} />
    </div>
  );
};

export default ResetPassword;
