import { createSelector } from "reselect";

const selectFixtureDetails = (state) => state.fixtureDetails;

export const selectFixture = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.fixture
);

export const selectEvents = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.events
);

export const selectTravelTime = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.travelTime
);

export const selectIsFetchingEvents = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.isFetchingEvents
);

export const selectStandings = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.standings
);

export const selectLastFiveMatches = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.lastFiveMatches
);

export const selectLeagueTeams = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.leagueTeams
);

export const selectH2h = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.h2h
);

export const selectSlicedH2h = createSelector(selectH2h, (h2h) => {
  let fixList = h2h && h2h.length > 6 ? h2h.slice(h2h.length - 6) : h2h;
  fixList =
    fixList &&
    fixList.sort((a, b) => {
      if (a.event_date < b.event_date) {
        return 1;
      }
      if (a.event_date > b.event_date) {
        return -1;
      }
      return 0;
    });

  return fixList;
});

export const selectOdds = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.odds
);

export const selectStadiumImages = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => {
    const { stadiumImages } = fixtureDetails;
    if (stadiumImages && stadiumImages.length) {
      return stadiumImages.map(({ url }, idx) => ({
        src: url,
        width: 3,
        height: idx % 2 === 0 ? 2 : 3,
      }));
    } else {
      return stadiumImages;
    }
  }
);

export const selectIsFetchingStadiumImages = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.isFetchingStadiumImages
);

export const selectIsFetchingFixtureDetails = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.isFetching
);

export const selectSupporters = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.supporters
);

export const selectSuppportersCoords = createSelector(
  selectSupporters,
  (supporters) => supporters.map((x) => x.location)
);

export const selectAttractions = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => {
    const attractions = fixtureDetails.attractions;
    if(attractions && attractions.length>0){
      const sortedAttractions = attractions.slice().sort((a, b) => {
        return a.distance - b.distance;
      });
      return sortedAttractions;
    }else{
      return attractions
    }
  }
);

export const selectIsErrorInMatchDetails = createSelector(
  selectFixtureDetails,
  (fixtureDetails) => fixtureDetails.isError
);