import React, { useContext, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import PropTypes from "prop-types";
import { MdMyLocation } from "react-icons/md";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { FiFilter } from "react-icons/fi";
import mapicon from "../../assets/images/map.png";
import { selectInternalMap } from "../../redux/map/mapSelectors";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import { selectFormValuesSearchedLocation } from "../../redux/formValues/formValuesSelectors";
import "./mapControls.scss";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import { Context } from "../../helpers/context/Context";
import { selectSearchBy } from "../../redux/fixtures/fixturesSelectors";


const MapControls = ({
  map,
  fetchMapDetails,
  userLocationCoords,
  searchedLocationCoords,
  setFilterModal,
  width,
  coords, 
  searchBy
}) => {
  const context = useContext(Context);
  const handleClick = (zoom) => {

    if(coords){
      console.log('test')
      fetchMapDetails({
        center: { lat: coords.lat, lng: coords.lng },
        marker: null,
        zoom: 7
      });
    }

    if (map) {
      const coords =
        searchedLocationCoords && searchedLocationCoords.lat
          ? searchedLocationCoords
          : userLocationCoords;

      const currentZoomLevel = map.getZoom();
      if (zoom === "in") map.setZoom(currentZoomLevel + 1);
      else if (zoom === "out") map.setZoom(currentZoomLevel - 1);
      else
        fetchMapDetails({
          center: { lat: coords.lat, lng: coords.lng },
        });
    }
  };


  return (
    <div className={`controls-btn`}>
      <div className="focus-btn">
        <div>
          <MdMyLocation
            onClick={handleClick}
            color="#2e2e2e"
            size={width <= 1000 ? 16 : 18}
            className="map-controls-icon"
          />
        </div>
      </div>
      <div onClick={() => setFilterModal(true)} className="leagues-btn">
        <div>
          <FiFilter className="mflb-side-icon" color="#8f8f8f" size={16} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  map: selectInternalMap,
  userLocationCoords: selectUserLocationCoords,
  searchedLocationCoords: selectFormValuesSearchedLocation,
  width: selectWidth,
  searchBy: selectSearchBy
});

const mapDispatchToProps = (dispatch) => ({
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
});

MapControls.propTypes = {
  map: PropTypes.object,
  fetchMapDetails: PropTypes.func,
  userLocationCoords: PropTypes.object,
  searchedLocationCoords: PropTypes.object,
  width: PropTypes.number,
  setFilterModal: PropTypes.func,
  selectSearchBy: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(MapControls);
