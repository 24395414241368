import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyD2EUbN8GTw0joJ629a5RiZlauA7S65-8M");
Geocode.setLanguage("en");
Geocode.enableDebug(false);

export const getUserLocation = (fetchUserLocationStart) => {
  fetchUserLocationStart();
  try {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          Geocode.fromLatLng(latitude, longitude).then(
            (res) => {                    
              if (res.status === "OK") {
                const address = res.results[0].formatted_address;
                res.results.forEach((x) =>
                  x.address_components.forEach((y) => {
                    if (y.types.includes("country")) {
                      return resolve({
                        address: address,
                        countryCode: y.short_name,
                        coordinates: {
                          lat: latitude,
                          lng: longitude
                        }
                      });
                    }
                  })
                );
              }
            },
            (error) => {
              console.error(error);
            }
          );
        },
        () => {
          return reject("Could not fetch user location.");
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
      );
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      return reject("Could not fetch user location.");
    });
  }
};
