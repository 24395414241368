import React, { useState, useEffect } from "react";
import axios from "axios";
import "./myProfile.scss";
import userProfile from "../../assets/images/user-profile.png";
import PropTypes from "prop-types"; // Import PropTypes for prop validation
import { useDispatch, useSelector } from "react-redux";
import { FAM_API } from "../../constants";
import { getCookie, setCookie } from "../../utils/cookies";
import { showError, showSuccess } from "../../components/ToastMessage";
import { SET_STATE } from "../../redux/auth/authTypes";
import SelectDropdown from "../../components/SelectDropdown/selectdropdown";
import { getCountriesList } from "../../redux/attractions/attractionServices";
import { userImg } from "../../assets/images/userIcon.png";
import { AiOutlineClose } from "react-icons/ai";
import MultiSelectComponent from "../../components/MultiSelect/MultiSelect";
import Mixpanel from "../../utils/mixPanel";

const options = [
  { value: "attraction1", label: "Attraction 1" },
  { value: "attraction2", label: "Attraction 2" },
  { value: "attraction3", label: "Attraction 3" },
];

const MyProfile = () => {
  const [countriesList, setCountriesList] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [teamText, setTeamText] = useState("");
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    aboutMe: "",
    favouriteTeams: [],
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [debouncedTeamText, setDebouncedTeamText] = useState("");

  const handleSelectChange = (option) => {
    setProfileData({ ...profileData, country: option.label });
    setSelectedOption(option);
    console.log("Selected Option:", option);
  };

  console.log("selectedTeams", selectedTeams, profileData);

  const handleSelectTeam = (option) => {
    setSelectedTeams(option);
    let team = option;
    team = team.map((t) => t.value);
    console.log("handleSelectTeam", option);
    setProfileData({
      ...profileData,
      favouriteTeams: option,
    });
  };

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  console.log("data", user);

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedTeamText(teamText);
    }, 500); // Delay of 500ms

    return () => {
      clearTimeout(timeoutId); // Clear the timeout if input changes within the delay
    };
  }, [teamText]);

  useEffect(() => {
    if (debouncedTeamText) {
      onCallTeamApi();
    }
  }, [debouncedTeamText]);

  const getAllCountries = async () => {
    const response = await getCountriesList();
    console.log("response", response);
    if (response?.data?.success) {
      const countries = response.data.data.map((country) => {
        return {
          value: country?._id,
          label: country?.name,
        };
      });
      setCountriesList(countries);
    }
  };

  // Effect to set user data when the user prop is passed
  useEffect(() => {
    if (user) {
      const foundCountry = countriesList.find(
        (country) => country.label === user.country
      );
      setSelectedOption(foundCountry);
      if (user.favouriteTeams.length > 0) {
        setSelectedTeams(
          user.favouriteTeams.map((team) => {
            return {
              label: team?.team?.name,
              value: team?._id,
              flagUrl: team?.team?.logo,
            };
          })
        );
      }
      setProfileData({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        country: user.country || "",
        aboutMe: user.aboutMe || "",
      });
    }
  }, [user, countriesList]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle update API call
  const handleUpdate = async () => {
    const payload = {
      ...profileData,
      favouriteTeams: profileData.favouriteTeams.map((team) => team.value),
    };
    delete payload.email;

    const token = getCookie("token");
    Mixpanel.track(`Updated Profile`)
    try {
      const response = await axios.patch(`${FAM_API}/update`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        showSuccess("Profile updated successfully");
        setUserInRedux();
      }
      console.log("Profile updated successfully:", response.data);
    } catch (error) {
      showError("There is some error while updating profile.");
      console.error("Error updating profile:", error);
    }
  };

  const setUserInRedux = async () => {
    const token = getCookie("token");
    try {
      const response = await axios.get(`${FAM_API}/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        dispatch({
          type: SET_STATE,
          payload: { user: response.data.data },
        });
        setCookie("user", JSON.stringify(response.data.data), 10);
      }
      console.log("Profile get successfully:", response.data);
    } catch (error) {
      console.error("Error get profile:", error);
    }
  };

  const onCallTeamApi = async () => {
    try {
      const response = await axios.get(
        `${FAM_API}/league-teams-search?text=${teamText}`
      );
      console.log("response.data", response.data);
      if (response.data.success) {
        if (response.data.data && response.data.data.teams.length > 0) {
          setTeamsList(
            response.data.data.teams.map((team) => {
              return {
                label: team.team.name,
                value: team._id,
                flagUrl: team.team.logo,
              };
            })
          );
        }
      }
      console.log("team api successfully:", response.data);
    } catch (error) {
      console.error("Error get teams:", error);
    }
  };

  const handleDeleteTeam = (id) => {
    const team = selectedTeams.filter((tm) => tm.value != id);
    setSelectedTeams(team);
    setProfileData({ ...profileData, favouriteTeams: team });
  };

  console.log("profileData.favouriteTeams", profileData.favouriteTeams);

  return (
    <div className="profile-page">
      <div className="user-img">
        <img src={userProfile} alt="user-img" />
      </div>
      <div className="user-data-container">
        <div className="user-data">
          <label>First Name:</label>
          <input
            type="text"
            name="firstName"
            value={profileData.firstName}
            onChange={handleChange}
            placeholder="John"
          />
        </div>
        <div className="user-data">
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={profileData.lastName}
            onChange={handleChange}
            placeholder="Doe"
          />
        </div>
        <div className="user-data">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={profileData.email}
            onChange={handleChange}
            placeholder="your-email@gmail.com"
            disabled
          />
        </div>
        <div className="user-data">
          <label>Country:</label>
          <div className="country-select-dropdown">
            <SelectDropdown
              options={countriesList}
              placeholder="Choose country"
              onChange={handleSelectChange}
              value={selectedOption}
            />
          </div>
        </div>
        <div className="user-data">
          <label>About Me:</label>
          <textarea
            name="aboutMe"
            value={profileData.aboutMe}
            onChange={handleChange}
            placeholder="Enter About yourself"
          />
        </div>

        <div className="user-data">
          <label>Favorite Teams:</label>
          {/* <input type="text" placeholder="United States" /> */}
          {/* <div className="country-select-dropdown">
            <SelectDropdown
              options={teamsList}
              onChange={handleSelectTeam}
              value={selectedTeams}
              placeholder="Choose Favorite Team"
              onInputChange={(text) => setTeamText(text)}
            />
          </div> */}
          <div className="fav-team-selector">
            <MultiSelectComponent
              placeholderText="Your Favorite Teams"
              searchBy="team"
              selectedOptions={selectedTeams}
              setSelectedOptions={handleSelectTeam}
              isSetting={true}
            />
          </div>
          <div className="tags-container">
            {Array.isArray(selectedTeams) &&
              selectedTeams.length > 0 &&
              selectedTeams.map((teams) => (
                <div className="tag" key={teams.flagUrl}>
                  <div className="tag-data">
                    <img src={teams.flagUrl} alt="flag-logo" />
                    {teams.label}
                  </div>
                  <AiOutlineClose
                    className="delete-icon"
                    onClick={() => handleDeleteTeam(teams.value)}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="button-container">
        <button className="update-btn" onClick={handleUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};

MyProfile.propTypes = {
  user: PropTypes.object,
};

export default MyProfile;
