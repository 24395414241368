import {
  takeLatest,
  put,
  all,
  call,
  select,
  takeEvery,
} from "redux-saga/effects";
import {
  FETCH_FIXTURES_BY_LOCATION_START,
  FETCH_FIXTURES_BY_COUNTRY_START,
  FETCH_MATCHES_BY_LOCATION_START,
  FETCH_MATCHES_BY_COUNTRY_START,
  FETCH_FIXTURES_BY_LEAGUES_START,
  CHECK_UPDATE_FIXTURES,
} from "./fixturesTypes";
import {
  selectUserLocationCountryCode,
  selectUserLocationCoords,
} from "../userLocation/userLocationSelectors";
import { selectNormalizedCountriesList } from "../countries/countriesSelectors";
import {
  selectFormValuesRadius,
  selectFormValuesSearchedLocation,
  selectFormValuesCountryCode,
  selectFormValuesDates,
  selectFormValuesCurrentDate,
} from "../formValues/formValuesSelectors";
import { getNearbyCountries } from "../../helpers/getNearbyCountries";
import {
  fetchMatchesByLocationStart,
  fetchMatchesByCountryStart,
  fetchFixturesByLocationFailure,
  fetchFixturesByLocationSuccess,
  fetchMatchdayLoaders,
  fetchFixturesByLeaguesSuccess,
  fetchFixturesByLeaguesFailure,
  matchSlitByDay,
  setFixture,
  fetchFixturesByLocationStart,
} from "./fixturesActions";
import { updateFixtures } from "../../helpers/updateFixtures";
import { getMatches } from "../../helpers/getMatches";
import {
  selectSelectedLeaguesIds,
  selectLeaguesList,
  selectNormalizedLeaguesList,
  selectSelectedStadiums,
  selectSelectedTeams,
} from "../leagues/leaguesSelectors";
import { getFixturesByLeagues } from "../../helpers/getFixturesByLeagues";
import { selectInternalMap } from "../map/mapSelectors";
import { fetchFilteringValues } from "../filteringValues/filteringValuesActions";
import { move, divideFixturesByDistance } from "../../utils";
import { selectIsAllCountries, selectMatches } from "./fixturesSelectors";

// import { selectWidth } from "../screenDimensions/screenDimensionsSelectors";

import { fetchSidebarDetails } from "../sidebar/sidebarActions";

//Leagues
import { getLeagues } from "../../helpers/getLeagues";
import {
  fetchLeaguesSuccess,
  fetchLeaguesFailure,
} from "../leagues/leaguesActions";
import { GiConsoleController } from "react-icons/gi";
import { getMatchesByCountry } from "../../helpers/getMatchesbyCountry";
import { selectFilterModalSelectedLeagues, selectFilterModalSelectedLeaguesIds } from "../filteringValues/filteringValuesSelectors";

function* checkUpdateFixtures() {
  yield call(updateFixtures);
}

function* fetchNearbyCountriesAsync() {
  // const startTime = performance.now(); 
  const isCountrySearch = yield select(selectIsAllCountries);
  const userLocationCountryCode = yield select(selectUserLocationCountryCode);
  const searchedLocationCountryCode = yield select(selectFormValuesCountryCode);
  const searchedLocation = yield select(selectFormValuesSearchedLocation);
  const deviceLocation = yield select(selectUserLocationCoords);
  const radius = yield select(selectFormValuesRadius);
  const countries = yield select(selectNormalizedCountriesList);
  const currentDate = yield select(selectFormValuesCurrentDate);
  const countryCode = searchedLocationCountryCode || userLocationCountryCode;
  const dates = yield select(selectFormValuesDates);
  const leaguesList = yield select(selectLeaguesList);

  const coords = searchedLocation || deviceLocation;

  const { fetchedCountries, region, error } = yield call(
    getNearbyCountries,
    coords,
    radius,
    countryCode,
    countries
  );

  if (!leaguesList.length) {
    const { leagues, error } = yield call(getLeagues);

    if (leagues) yield put(fetchLeaguesSuccess(leagues));
    else yield put(fetchLeaguesFailure(error));
  }
  const country = fetchedCountries?.find((e) => e.code === countryCode);
  if (!error) {
    // for (const item in dates) {
      // const currentDate = dates[item];
      // const apiStartTime = performance.now(); 
      yield put(
        fetchMatchesByLocationStart({
          fetchedCountries,
          currentDate:dates,
          coords,
          radius,
          region,
          dates,
          country: country?.country,
        })
      );
      // const apiEndTime = performance.now(); // End timing for each API call
      // console.log(`Time taken for fetchMatchesByLocationStart: ${(apiEndTime - apiStartTime)/1000} s`);
    // }
  } else {
    yield put(fetchFixturesByLocationFailure(error));
  }
}

function* watchFetchNearbyCountries() {
  yield takeLatest(FETCH_FIXTURES_BY_LOCATION_START, fetchNearbyCountriesAsync);
}

const allLoaders = {};

// function* fetchMatchesAsync({
//   payload: {
//     fetchedCountries,
//     currentDate,
//     coords,
//     radius,
//     region,
//     dates,
//     country,
//   },
// }) {
//   const startTime = performance.now(); // Start timing
//   let matches = yield select(selectMatches);

//   allLoaders[currentDate] = true;
//   yield put(fetchMatchdayLoaders(allLoaders));

//   const { fixtures, leagues } = yield call(
//     getMatches,
//     fetchedCountries,
//     currentDate,
//     coords,
//     radius,
//     region,
//     country
//   );

//   const completeLeaguesList = yield select(selectNormalizedLeaguesList);

//   let selectedLeaguesIds;
//   let selectedLeagues;

 

//   const fixturesWithRadius = yield call(
//     divideFixturesByDistance,
//     fixtures,
//     radius,
//     false
//   );
  
//   //put the loader
   
//   if (completeLeaguesList) {
//     if (fixturesWithRadius.insideRadiusFixtures) {
//       const preleagues = yield select(selectFilterModalSelectedLeagues);
//       const insideRadiusLeagueIds = fixturesWithRadius.insideRadiusFixtures?.map(x => x?.league_id) || [];
//       const combinedLeagues = preleagues?[...preleagues.map(x=>x?.league_id), ...insideRadiusLeagueIds]:insideRadiusLeagueIds;
//       selectedLeaguesIds = yield [
//         ...new Set(combinedLeagues),
//       ];
//       selectedLeagues = yield selectedLeaguesIds.map(
//         (x) => completeLeaguesList[x]
//       );
//     } else {
//       selectedLeaguesIds = [];
//       selectedLeagues = [];
//     }

//     yield put(fetchFilteringValues({ selectedLeagues,tempselectedLeagues:selectedLeagues}));
//   }
//   matches[currentDate] = fixturesWithRadius;

//   allLoaders[currentDate] = false;
//   yield put(fetchMatchdayLoaders(allLoaders));

//   // matches[currentDate]["leagues"] = selectedLeagues || null;
//   // matches[currentDate]['searchBy'] = "location";
//   if (
//     dates.findIndex((i) => {
//       if (i === currentDate) return true;
//     }) === 0
//   ) {
//     yield put(
//       fetchFixturesByLocationSuccess({
//         leagues:  null,
//         fixtures: fixturesWithRadius,
//         searchBy: "location",
//       })
//     );

//     //change thıs
//     // yiel
//     yield put(fetchSidebarDetails({ areFixturesFetched: true }));
//   }
//   yield put(matchSlitByDay(matches));
//   const endTime = performance.now(); // End timing for the whole function
//   console.log(`Total time taken for fetchMatchesAsync:::::: ${(endTime - startTime)/1000} s`);
// }
function* fetchMatchesAsync({
  payload: {
    fetchedCountries,
    currentDate,
    coords,
    radius,
    region,
    dates,
    country,
  },
}) {
  // const startTime = performance.now(); // Start timing
  let matches = yield select(selectMatches);

  // allLoaders[currentDate] = true;
  // yield put(fetchMatchdayLoaders(allLoaders));

  const { fixtures, leagues } = yield call(
    getMatches,
    fetchedCountries,
    currentDate,
    coords,
    radius,
    region,
    country
  );

  const completeLeaguesList = yield select(selectNormalizedLeaguesList);

  let selectedLeaguesIds;
  let selectedLeagues;

 

  const fixturesWithRadius = yield call(
    divideFixturesByDistance,
    fixtures,
    radius,
    false
  );
  
  //put the loader
   
  if (completeLeaguesList) {
    if (fixturesWithRadius.insideRadiusFixtures) {
      const preleagues = yield select(selectFilterModalSelectedLeagues);
      const insideRadiusLeagueIds = fixturesWithRadius.insideRadiusFixtures?.map(x => x?.league_id) || [];
      const combinedLeagues = preleagues?[...preleagues.map(x=>x?.league_id), ...insideRadiusLeagueIds]:insideRadiusLeagueIds;
      selectedLeaguesIds = yield [
        ...new Set(combinedLeagues),
      ];
      selectedLeagues = yield selectedLeaguesIds.map(
        (x) => completeLeaguesList[x]
      );
    } else {
      selectedLeaguesIds = [];
      selectedLeagues = [];
    }

    yield put(fetchFilteringValues({ selectedLeagues,tempselectedLeagues:selectedLeagues}));
  }
  // matches[currentDate] = fixturesWithRadius;

  // allLoaders[currentDate] = false;
  // yield put(fetchMatchdayLoaders(allLoaders));
  const matchess = {};
  const allLoaders = {};
  dates.forEach((item) => {
    const a = {
      leagues,
      insideRadiusFixtures: [],
      outsideRadiusFixtures: []
    };
  
    const dateItem = item.split("T")[0];
  
    [...fixturesWithRadius.insideRadiusFixtures, ...fixturesWithRadius.outsideRadiusFixtures].forEach((fix) => {
      const day = fix.event_date.split("T")[0];
      if (day === dateItem) {
        if (fixturesWithRadius.insideRadiusFixtures.includes(fix)) {
          a.insideRadiusFixtures.push(fix);
        } else {
          a.outsideRadiusFixtures.push(fix);
        }
      }
    });
  
    matchess[item] = a;
    allLoaders[item] = false;
  });
  

  yield put(fetchMatchdayLoaders(allLoaders));
  yield put(matchSlitByDay(matchess));
  // yield put(fetchLeaguesSuccess(sortedLeagues));
  // matches[currentDate]["leagues"] = selectedLeagues || null;
  // matches[currentDate]['searchBy'] = "location";
  // if (
  //   dates.findIndex((i) => {
  //     if (i === currentDate) return true;
  //   }) === 0
  // ) {
    yield put(
      fetchFixturesByLocationSuccess({
        leagues:  null,
        fixtures: fixturesWithRadius,
        searchBy: "location",
      })
    );

    //change thıs
    // yiel
    yield put(fetchSidebarDetails({ areFixturesFetched: true }));
  // }
  // yield put(matchSlitByDay(matches));
  // const endTime = performance.now(); // End timing for the whole function
  // console.log(`Total time taken for fetchMatchesAsync:::::: ${(endTime - startTime)/1000} s`);
}

function* fetchMatchesByCountryAsync({
  payload: {
    fetchedCountries,
    currentDate,
    coords,
    radius,
    region,
    dates,
    country,
  },
}) {
  let matches = yield select(selectMatches);

  //put the loader
  const preleagues = yield select(selectFilterModalSelectedLeaguesIds);
  allLoaders[currentDate] = true;
  yield put(fetchMatchdayLoaders(allLoaders));

  const { fixtures, leagues } = yield call(
    getMatchesByCountry,
    fetchedCountries,
    currentDate,
    coords,
    radius,
    region,
    country
  );

  const completeLeaguesList = yield select(selectNormalizedLeaguesList);

  let selectedLeaguesIds;
  let selectedLeagues;
  const fixturesWithRadius = yield call(
    divideFixturesByDistance,
    fixtures,
    radius,
    false
  );
  if (completeLeaguesList) {
    if (fixturesWithRadius.insideRadiusFixtures) {
      selectedLeaguesIds = yield [
        ...new Set(fixturesWithRadius.insideRadiusFixtures?.map((x) => x?.league_id)),
      ];
      selectedLeagues = yield selectedLeaguesIds.map(
        (x) => completeLeaguesList[x]
      );
    } else {
      selectedLeaguesIds = [];
      selectedLeagues = [];
    }

    yield put(fetchFilteringValues({ selectedLeagues }));
  }



  matches[currentDate] = fixturesWithRadius;

  allLoaders[currentDate] = false;
  yield put(fetchMatchdayLoaders(allLoaders));

  matches[currentDate]["leagues"] = selectedLeagues || null;
  // matches[currentDate]['searchBy'] = "location";
  if (
    dates.findIndex((i) => {
      if (i === currentDate) return true;
    }) === 0
  ) {
    yield put(
      fetchFixturesByLocationSuccess({
        leagues:  null,
        fixtures: fixturesWithRadius,
        searchBy: "location",
      })
    );

    //change thıs
    // yiel
    yield put(fetchSidebarDetails({ areFixturesFetched: true }));
  }
  yield put(matchSlitByDay(matches));
}

function* watchFetchMatches() {
  yield takeEvery(FETCH_MATCHES_BY_LOCATION_START, fetchMatchesAsync);
  yield takeLatest(CHECK_UPDATE_FIXTURES, checkUpdateFixtures);
}

function* watchFetchMatchesByCountry() {
  yield takeEvery(FETCH_MATCHES_BY_COUNTRY_START, fetchMatchesByCountryAsync);
  yield takeLatest(CHECK_UPDATE_FIXTURES, checkUpdateFixtures);
}

function* fetchFixturesByLeaguesAsync(props) {
  const deviceLocation = yield select(selectUserLocationCoords);
  const radius = yield select(selectFormValuesRadius);
  const dates = yield select(selectFormValuesDates);
  const leaguesIds = yield select(selectSelectedLeaguesIds);
  const stadiumList = yield select(selectSelectedStadiums);
  const teamList = yield select(selectSelectedTeams);
  const completeLeaguesList = yield select(selectLeaguesList);
  const map = yield select(selectInternalMap);

  const stadiumIds = stadiumList.map((stadium) => stadium.value);
  const teamIds = teamList.map((team) => team.value);

  console.log("stadiumIds", stadiumList, "teamIds", teamList);

  const { fixtures, leagues, error } = yield call(
    getFixturesByLeagues,
    dates,
    leaguesIds,
    deviceLocation,
    radius,
    stadiumIds,
    teamIds
  );

  try {
    if (!error) {
      // const width = yield select(selectWidth);
      const completeNormalizedLeaguesList = yield select(
        selectNormalizedLeaguesList
      );
      let selectedLeaguesIds;
      let selectedLeagues;
      if (completeLeaguesList) {
        selectedLeaguesIds = yield [
          ...new Set(fixtures.map((x) => x.league_id)),
        ];
        selectedLeagues = selectedLeaguesIds.map(
          (x) => completeNormalizedLeaguesList[x]
        );
        yield put(fetchFilteringValues({ selectedLeagues,tempselectedLeagues:selectedLeagues }));
      }
      const sortedLeagues = yield call(move, completeLeaguesList, leaguesIds);

      const fixturesWithRadius = yield call(
        divideFixturesByDistance,
        fixtures,
        radius,
        true
      );

      const matches = {};
      const allLoaders = {};
      dates.map((item, index) => {
        const a = {};
        a["leagues"] = leagues;
        a["insideRadiusFixtures"] = [];
        a["outsideRadiusFixtures"] = [];
        fixturesWithRadius.insideRadiusFixtures.forEach((fix) => {
          const day = fix.event_date.split("T");
          if (day[0] === item) a["insideRadiusFixtures"].push(fix);
        });
        fixturesWithRadius.outsideRadiusFixtures.forEach((fix) => {
          const day = fix.event_date.split("T");
          if (day[0] === item) a["outsideRadiusFixtures"].push(fix);
        });
        matches[item] = a;
        allLoaders[item] = false;
      });

      yield put(fetchMatchdayLoaders(allLoaders));
      yield put(matchSlitByDay(matches));
      yield put(fetchLeaguesSuccess(sortedLeagues));
      yield put(
        fetchFixturesByLeaguesSuccess({
          leagues: selectedLeagues || null,
          fixtures: matches[dates[0]],
          searchBy: "leagues",
        })
      );

      yield put(fetchSidebarDetails({ areFixturesFetched: true }));

      props.payload.clearText();
      if (fixtures) {
        const firstDay = [
          ...matches[dates[0]].insideRadiusFixtures,
          ...matches[dates[0]].outsideRadiusFixtures,
        ];
        const markers = [
          ...firstDay
            .filter((f) => f.venue)
            .map((fix) =>
              Object.assign(
                {},
                {
                  lat: fix.location.lat,
                  lng: fix.location.lng,
                }
              )
            ),
          { ...deviceLocation },
        ];
        var bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
          bounds.extend(markers[i]);
        }
        setTimeout(() => {
          if (map) {
            map.fitBounds(bounds);
          }
        }, 0);
      }
    } else {
      yield put(fetchFixturesByLeaguesFailure(error));
    }
  } catch (err) {
    console.warn({ err });
  }
}

function* watchFetchFixturesByLeagues() {
  yield takeLatest(
    FETCH_FIXTURES_BY_LEAGUES_START,
    fetchFixturesByLeaguesAsync
  );
}

export default function* fixturesSagas() {
  yield all([
    call(watchFetchNearbyCountries),
    call(watchFetchMatches),
    call(watchFetchMatchesByCountry),
    call(watchFetchFixturesByLeagues),
  ]);
}
