import { FAM_API } from "../constants";
import axios from "axios";

export const getFixturesByLeagues = async (
  dates,
  leaguesIds,
  deviceLocation,
  radius,
  stadiumIds,
  teamIds
) => {
  const { lat, lng } = deviceLocation;

  try {
    let URL = `${FAM_API}/fixtures?dates=${dates.join()}&coordinates=${lat},${lng}&radius=${radius}`;
    if (stadiumIds.length > 0) {
      URL = `${URL}&stadiums=${stadiumIds.join()}`;
    }

    if (teamIds.length > 0) {
      URL = `${URL}&teams=${teamIds.join()}`;
    }

    if (leaguesIds.length > 0) {
      URL = `${URL}&leagues=${leaguesIds.join()}`;
    }

    const response = await axios.get(URL);

    const leaguesList = response.data.leaguesList;

    const venueLocations = response.data.venueLocations;
    // if (!venueLocations.length) {
    //   throw new Error();
    // }

    return { leagues: leaguesList, fixtures: venueLocations };
  } catch (error) {
    return { error: "Could not fetch fixtures" };
  }
};
