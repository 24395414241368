import React, { useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.scss";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Mixpanel from "../../utils/mixPanel";

// Custom input component for mobile screens
const CustomInput = ({ value, onClick }) => (
  <button className="custom-input" onClick={onClick}>
    {value || "Select Date"}
  </button>
);

const Calendar = ({ startDate, setStartDate, endDate, setEndDate, type }) => {
  const startDatePickerRef = useRef();
  const endDatePickerRef = useRef();
  const screen = useSelector((state) => state.screenDimensions);
  const isMobileScreen = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);

  useEffect(() => {
    if (type === "filterModal") {
      startDatePickerRef.current.setOpen(false);
      endDatePickerRef.current.setOpen(false);
    }
  }, [type]);

  return (
    <>
      <div
        className={`datepicker-container ${
          type === "filterModal" && "filterModal-datepicker-container"
        }`}>
        <DatePicker
          selected={startDate}
          ref={startDatePickerRef}
          onChange={(date) => {
            Mixpanel.track(`Setting Start Date ${date}`);
            setStartDate(date);
            setEndDate(date);
            endDatePickerRef.current.setOpen(true);
          }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          minDate={new Date()}
          placeholderText="Enter From Date"
          dateFormat="MMMM d, yyyy"
          calendarClassName={`${
            type === "filterModal"
              ? "filterModal-datepicker"
              : "form-datepicker"
          }`}
          customInput={isMobileScreen ? <CustomInput /> : null} // Custom input for mobile
        />
      </div>

      <div
        className={`datepicker-container ${
          type === "filterModal"
            ? "filterModal-datepicker-container"
            : "form-datepicker"
        }`}>
        <DatePicker
          selected={endDate}
          ref={endDatePickerRef}
          onChange={(date) => {   Mixpanel.track(`Setting End Date ${date}`); setEndDate(date)}}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={new Date(moment(startDate).add(20, "days"))}
          placeholderText="Enter To Date"
          dateFormat="MMMM d, yyyy"
          calendarClassName={`${
            type === "filterModal"
              ? "filterModal-datepicker"
              : "form-datepicker"
          }`}
          customInput={isMobileScreen ? <CustomInput /> : null} // Custom input for mobile
        />
      </div>
    </>
  );
};

Calendar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default Calendar;
