import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";

import CustomTabButton from "../CustomTabButton";
import FilterByLeagues from "../FilterByLeagues";
import FilterByLocation from "../FilterByLocation";
import "./searchForm.scss";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import Ad from "../Ad/Ad";
import {
  fetchSelectedLeague,
  fetchSelectedStadium,
  fetchSelectedTeam,
} from "../../redux/leagues/leaguesActions";
import { fetchFormValues } from "../../redux/formValues/formValuesActions";
import Mixpanel from "../../utils/mixPanel";
const SearchForm = ({ width }) => {
  const [index, setIndex] = useState(() =>
    window.location.href.includes("searchByLeagues") ||
    window.location.href.includes("leagueId")
      ? 1
      : 0
  );

  const dispatch = useDispatch();

  return (
    <div className={`search-form-container`}>
      <div className="tab-buttons">
        <CustomTabButton
          text="Search By Location"
          selected={index === 0 ? true : false}
          callback={() => {
            Mixpanel.track('Clicked on Location Search');
            setIndex(0);
            dispatch(fetchSelectedLeague([]));
            dispatch(fetchSelectedStadium([]));
            dispatch(fetchSelectedTeam([]));
            dispatch(
              fetchFormValues({
                leagueSearch: "",
              })
            );
          }}
        />
        <CustomTabButton
          text="Advanced search"
          selected={index === 1 ? true : false}
          callback={() =>{Mixpanel.track('Clicked on Advance Search'); setIndex(1)}}
        />
      </div>
      <div className={`search-form ${index === 1 && "remove-form-margin"}`}>
        {!index ? (
          <FilterByLocation setIndex={setIndex} />
        ) : (
          <FilterByLeagues />
        )}
        {index === 0 && width > 1000 ? (
          <div style={{ marginTop: 50 }}>
            <Ad slot="7944567579" styles={{ width: 390, height: 150 }} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  width: selectWidth,
});

SearchForm.propTypes = {
  width: PropTypes.number,
};

export default connect(mapStateToProps)(SearchForm);
