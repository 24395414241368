import React, { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { BsCheck } from "react-icons/bs";
import PropTypes from "prop-types";
import "./leaguesListItems.scss";
import { trimString } from "../../helpers/trimString";
import { connect } from "react-redux";
import { fetchSelectedLeague } from "../../redux/leagues/leaguesActions";
import {
  selectSelectedLeaguesIds,
  selectSelectedLeagues,
} from "../../redux/leagues/leaguesSelectors";
import { fetchIsAllLeaguesChecked } from "../../redux/filteringValues/filteringValuesActions";

const createSelectedList = (arr, arrIds, league, type) => {
  return arrIds.includes(league.league_id)
    ? arr.filter((x) => x.league_id !== league.league_id)
    : type === "filterModal"
    ? [...arr, league]
    : arr.length < 700
    ? [...arr, league]
    : [...arr];
};

const LeaguesListItem = ({
  matchLeagues,
  league,
  fetchSelectedLeague,
  fetchIsAllLeaguesChecked,
  selectedLeaguesIds,
  selectedLeagues,
  type,
  selectedLeaguesList,
  setSelectedLeaguesList,
  selectedLeaguesListIds,
  setSelectedLeaguesListIds,
}) => {
  useEffect(() => {
    matchLeagues?.length === selectedLeaguesList?.length
      ? fetchIsAllLeaguesChecked({ isAllLeaguesChecked: true })
      : fetchIsAllLeaguesChecked({ isAllLeaguesChecked: false });
  }, [selectedLeaguesList]);
  
  const handleClick = () => {
    if (type === "filterModal") {
      
      const list = createSelectedList(
        selectedLeaguesList,
        selectedLeaguesListIds,
        league,
        type
      );

      setSelectedLeaguesList(list);
      setSelectedLeaguesListIds(list.map((x) => x.league_id));
    } else {
      const list = createSelectedList(
        selectedLeagues,
        selectedLeaguesIds,
        league,
        type
      );

      fetchSelectedLeague(list);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`league-item ${
        (type !== "filterModal"
          ? selectedLeaguesIds.includes(league?.league_id)
          : selectedLeaguesListIds.includes(league?.league_id)) &&
        "selected-league-item"
      } `}
    >
      <img className="league-logo" src={league?.logo} alt="logo"  onError={(e) => e.target.src = league?.logo} />
      <span className="league-name">
        {trimString(league?.name, type === "filterModal" ? 15 : 25)}
      </span>
      {(type !== "filterModal"
        ? selectedLeaguesIds.includes(league?.league_id)
        : selectedLeaguesListIds.includes(league?.league_id)) && (
        <div className="checkmark-icon">
          <BsCheck color="white" size={18} />
        </div>
      )}
      <span className="country-name">
        {league?.country === "World"
          ? league?.region
          : trimString(league?.country, 25)}
      </span>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
  fetchIsAllLeaguesChecked: (payload) => dispatch(fetchIsAllLeaguesChecked(payload)),
});

const mapStateToProps = createStructuredSelector({
  selectedLeaguesIds: selectSelectedLeaguesIds,
  selectedLeagues: selectSelectedLeagues,
});

LeaguesListItem.propTypes = {
  matchLeagues: PropTypes.array,
  fetchIsAllLeaguesChecked: PropTypes.func,
  league: PropTypes.object,
  fetchSelectedLeague: PropTypes.func,
  selectedLeaguesIds: PropTypes.array,
  selectedLeagues: PropTypes.array,
  type: PropTypes.string,
  selectedLeaguesList: PropTypes.array,
  setSelectedLeaguesList: PropTypes.func,
  selectedLeaguesListIds: PropTypes.array,
  setSelectedLeaguesListIds: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaguesListItem);
